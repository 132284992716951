.TopBar {
  width: 100%;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;

  .left,
  .right {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left {
    img {
      width: 46px;
      height: 46px;
      border-radius: 12px;
      margin-right: 16px;
    }
  }

  .right {
    cursor: pointer;
  }

  .right:hover {
    opacity: 0.8;
  }
}