.Home {
  width: 100vw;
  max-width: 1280px;
  margin: 0px auto;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      margin: 0px;
      padding: 0px;
    }
  }

  .banner {
    max-width: 90vw;
    height: auto;
  }

  .msg_container {
    margin-top: 64px;
  }

  .text {
    text-align: center;
    max-width: 90vw;
    width: 640px;
  }

  .imgRow {
    margin: 72px 0px;
    max-width: 80vw;
    display: flex;
    justify-content: space-between;
    gap: 36px;

    .img_sc {
      flex: 1;
      max-width: calc(33.33% - 36px);
      object-fit: contain;
    }

    @media only screen and (max-width: 720px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .img_sc {
        flex: unset;
        max-width: 95%;
        object-fit: contain;
      }
    }

  }

  .superstar {
    margin: 96px 0px;
    width: 118px;
    height: auto;
  }

  .download_btns {
    margin-top: 64px;
    margin-bottom: 128px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .btn_download {
      margin: 12px;
      cursor: pointer;
    }

    .btn_download:hover {
      opacity: 0.9;
    }
  }
}