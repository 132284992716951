body {
  margin: 0px;
  padding: 0px;
  font-family: Arial, Helvetica, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image:
    url('/public/imgs/bg.png'),
    linear-gradient(to right, #050F20, #162A63);
  background-repeat: repeat;
  background-size: auto;

  color: #FFF
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}